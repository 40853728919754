import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import {Button, Card, Col, Row} from 'antd';
import "./index.less";
import Video from '../components/video';


const hstyle = {
  textAlign: 'center',
  marginBottom: 0
};
const IndexPage = () => (
  <Layout isHideHeader={true}>
    <SEO title="Bla balb lba home" />

    <h2 style={hstyle}>So funktioniert es</h2>
    <div className="video-wrapper-wrapper">
      <div className="video-wrapper">
        <Video videoSrcURL="https://www.youtube.com/embed/DHDrj0_bMQ0"
               width='720'
               height='405' />
      </div>
    </div>

    <Row gutter={16}
         align="stretch"
         justify="center">
      <Col span={8}>
        <Card title="Ohne Kostenrisiko"
              actions={[
                <Button href="/faq/">Mehr erfahren</Button>
              ]}>
          Sie zahlen nur etwas, wenn ... Sie erfolgreich waren! Das heißt: Keine Vorabzahlung wie bei dem*der Anwält*in.
          Die Höhe des Honorars hängt davon ab ... für Sie erzielt
        </Card>
      </Col>
      <Col span={8}>
        <Card title="Kein Austausch mit der Schule"
              actions={[
                <Button href="/faq/">Mehr erfahren</Button>
              ]}>
          Der Austausch findet direkt mit der zuständigen Schulbehörde statt. Ihr Kind muss also keine Nachteile
          befürchten.
        </Card>
      </Col>

      <Col span={8}>
        <Card title="Direkt anmelden"
              actions={[
                <Button href="/form/">Direkt zum Anmeldeformular</Button>
              ]}>
          Bla bla bla unkompliziert Lorem ipsum dolor sit amet, consectetur adipisicing elit. Eum inventore itaque
          molestias tenetur vel. Aspernatur consequuntur corporis ipsam saepe!
        </Card>
      </Col>
      {/*<Col span={8}>*/}
      {/*  <Card title="Fragen und Antworten"*/}
      {/*        bordered={true}>*/}
      {/*    <ul>*/}
      {/*      <li>FAQ checken</li>*/}
      {/*    </ul>*/}
      {/*  </Card>*/}
      {/*</Col>*/}
      {/*<Col span={8}>*/}
      {/*  <Card title="Hintergrundinformationen"*/}
      {/*        bordered={true}>*/}
      {/*    Hintergrundinformationen*/}
      {/*  </Card>*/}
      {/*</Col>*/}
      {/*<Col span={8}>*/}
      {/*  <Card title="Anspruch anmelden"*/}
      {/*        bordered={true}>*/}
      {/*    <Button type="primary">Jetzt registrieren</Button>*/}
      {/*  </Card>*/}
      {/*</Col>*/}
    </Row>

    {/*<Link to="/page-2/">Go to page 2</Link> <br />*/}
    {/*<Link to="/using-typescript/">Go to "Using TypeScript"</Link>*/}
  </Layout>
);

export default IndexPage;
